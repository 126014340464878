<template>
  <sdCards headless>
    <nav class="settings-menmulist" style="padding-top:0px !important;">
      <ul>
        <li>
          <router-link :to="{ name: 'admin-set-profile' }">
            <font-awesome-icon style="margin-right: 10px;" icon="fa-regular fa-user" size="1x" />
            Profile Details
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'admin-set-cookie' }">
            <font-awesome-icon style="margin-right: 10px;" icon="fa-solid fa-cookie-bite" size="1x" />
            Cookie Details
          </router-link>
        </li>
      </ul>
    </nav>
  </sdCards>
</template>
<script>
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far);
export default {
  name: 'AuthorBox',
  components: {
    FontAwesomeIcon
  },
  computed: {
    user: function() {
      return this.$store.getters.authUser;
    },
  },
};
</script>
